/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from "react-redux"
import { trackEvent } from "../../helpers/services"
import { getContentData, getIsAuth } from "../../redux/userSelectors"

const Footer = () => {
  const content = useSelector(getContentData)
  const isAuth = useSelector(getIsAuth)

  return (
    <footer className="footer">
      <div className="footer__holder container">
        <nav className="footer__nav">
          <div className="footer__nav-item">
            <a
              href={`${content[isAuth ? "footer_link1_url" : "login_footer_link1_url"]}`}
              target="_blank"
              className="footer__nav-link"
              onClick={() =>
                trackEvent("click", {
                  pbmElement: "footer-imprint-link",
                })
              }
              rel="noreferrer"
            >
              {content[isAuth ? "footer_link1_text" : "login_footer_link1_text"]}
            </a>
          </div>
          <div className="footer__nav-item">
            <a
              href={`${content[isAuth ? "footer_link2_url" : "login_footer_link2_url"]}`}
              target="_blank"
              className="footer__nav-link"
              onClick={() =>
                trackEvent("click", {
                  pbmElement: "footer-gdpr-link",
                })
              }
              rel="noreferrer"
            >
              {content[isAuth ? "footer_link2_text" : "login_footer_link2_text"]}
            </a>
          </div>
        </nav>
        <div className="footer__copyright">{`© ${new Date().getFullYear()} Volksbanken Raiffeisenbanken. Alle Rechte vorbehalten.`}</div>
      </div>
    </footer>
  )
}

export default Footer
