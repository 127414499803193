import {Link} from "react-router-dom"
import { trackEvent } from '../../helpers/services'
import { useNavigate, useLocation } from "react-router-dom"

const Header = () => {
	const navigate = useNavigate()
	const {pathname} = useLocation();

	const handleClick = () => {
		localStorage.removeItem("accessToken")
		navigate(`/login:${localStorage.getItem("username")}`)
	}

	return(
		<header className="header">
			<div className="header__holder container">
				<strong className="header__logo">
					<Link 
					  to="/" 
					  className="header__logo-link"
					  onClick={() => trackEvent('click', {
						pbmElement: 'nav-logo'
					  })}
					>
						Volksbanken Raiffeisenbanken
					</Link>
				</strong>
				{!pathname.includes('/login') && <button onClick={handleClick} className="btn btn--secondary">Abmelden</button>}
			</div>
		</header>
	)
}

export default Header