import { useEffect, Suspense, lazy } from 'react'
import { useSelector } from "react-redux"
import { Routes, Route, Navigate, useNavigate, useLocation} from 'react-router-dom'
import Header from "./components/Header"
import Footer from "./components/Footer"
import Preloader from "./components/Preloader"
import Layout from "./components/Layout"
import { getPersonalDataLoaded, getUserData } from "./redux/userSelectors"
import { RequireAuth } from './components/RequireAuth/RequireAuth'
import { WithPersonalData } from './components/WithPersonalData/WithPersonalData'

const Login = lazy(() => import('./pages/Login'))
const Landing = lazy(() => import('./pages/Landing'))
const Questionnaire = lazy(() => import('./pages/Questionnaire'))
const Result = lazy(() => import('./pages/Result'))
const PageNotFound = lazy(() => import('./pages/404'))

function App() {
  const navigate = useNavigate()
  const {pathname} = useLocation();
  const {check_finished, precheck_finished} = useSelector(getUserData)
  const personalDataLoaded = useSelector(getPersonalDataLoaded)
  
  useEffect(()=> {
    const shouldRedirect = personalDataLoaded && !pathname.includes('/login')
      if(shouldRedirect){
     
        if(precheck_finished === 0 || precheck_finished === 9) return navigate('/questionnaire')
        if(precheck_finished === 1 && check_finished === 0) return navigate("/")
        if(check_finished === 1 && precheck_finished === 1) return navigate("/result")
    }
},[precheck_finished, 
  check_finished, 
  navigate,
  personalDataLoaded,
  pathname 
  ])

  return (
    <Layout>
      <Header />
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login/:userId" element={<Login />} />
          <Route element={<RequireAuth />}>
              <Route element={<WithPersonalData/>}>
                <Route path="/" element={<Landing />} />
                <Route path="/questionnaire" element={<Questionnaire />} />
                <Route path="/result" element={<Result />} />
            </Route>
          </Route>
          <Route path="/404" element={<PageNotFound />} />
          <Route path='*' element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
      <Footer />
    </Layout>
  )
}

export default App
