export const getFormatedUserData = (customerData, landingCustomerData) => {
    const initialUserData = {...Object.values(customerData)[0]}
    const structuredUserData = {}
    
    initialUserData.customerAttributeMappingCollection.forEach(z => {
      const newValue = initialUserData[z.target_key] ? initialUserData[z.target_key] : landingCustomerData[z.target_key]
        structuredUserData[z.source_key] = newValue || null

    })
    structuredUserData.customer_profession_type = initialUserData.customer_profession_type
    structuredUserData.customer_insurance_type = initialUserData.customer_insurance_type
    structuredUserData.has_children = initialUserData.has_children
    return structuredUserData
  }