import axios from "axios";
import { store } from "../redux/store";

axios.defaults.headers["Content-Type"] = "application/json";
// test
const baseTrackURL = process.env.REACT_APP_TRACKING_SERVICE_URL;
const baseURL = process.env.REACT_APP_CUSTOMER_SERVICE_URL;
const mediaURL = process.env.REACT_APP_MEDIA_SERVICE_URL;
const keycloakURL = process.env.REACT_APP_KEYCLOAK_SERVICE_URL;

export const baseAxios = axios.create({
  baseURL: baseURL,
});
export const mediaAxios = axios.create({
  baseURL: mediaURL,
});
// dev
// const baseTrackURL = 'https://tracking.dev1.pbm.sh/api/v1'
// const baseURL = 'https://customer.dev1.pbm.sh/api/v1'
// const mediaURL = 'https://media.dev1.pbm.sh/api/v1'

let srcSource = localStorage.getItem("userCameFrom");
const campaignUuid = process.env.REACT_APP_CAMPAIGN_UUID;

export const login = (username, password) =>
  axios.post(`${baseURL}/auth/login`, {
    username,
    password,
  });

export const getLoginPersonalization = (username) =>
  mediaAxios.get(`external/login-personalization/${campaignUuid}/${username}`);

export const getLandingPageData = () =>
  mediaAxios.get(`external/landingpage/${campaignUuid}`);

export const retrieveAccessKeycloakToken = async () => {
  const { data } = await axios.post(
    keycloakURL,
    {
      grant_type: process.env.REACT_APP_CLOAK_GRANT_TYPE,
      username: process.env.REACT_APP_CLOAK_USERNAME,
      password: process.env.REACT_APP_CLOAK_PASSWORD,
      client_id: process.env.REACT_APP_CLOAK_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLOAK_CLIENT_SECRET,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "",
      },
    }
  );

  return data?.access_token;
};

export const createCustomer = (newCustomer, token) => {
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return baseAxios.post(`admin/customer`, newCustomer, config);
};

export const getUser = (customerUuid) =>
  baseAxios.get(`external/customer/${campaignUuid}/${customerUuid}`);

export const postCustomerPersonalization = (options) => {
  return mediaAxios.post(
    `external/personalization/${campaignUuid}/run`,
    {
      channels: options.channels,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export const pollCustomerPersonalization = (updatedAt) =>
  poll({
    fn: () => getLandingPageData(),
    validateFn: (data) => {
      const newUpdatedDate = data?.updated_at;
      return new Date(newUpdatedDate) > new Date(updatedAt);
    },
    delay: 5000,
    times: 24,
  });

export const pollCustomerVideo = () =>
  poll({
    fn: () => getLandingPageData(),
    validateFn: (data) => {
      return !!data.personalized_data.videoUrl;
    },
    delay: 5000,
    times: 24,
  });

export const setUser = (data) => {
  const customerUuid = store.getState().userData.customerUuid;
  return baseAxios.patch(
    `external/customer/${campaignUuid}/${customerUuid}`,
    data
  );
};

export const renewCustomer = () => {
  const customerUuid = store.getState().userData.customerUuid;
  baseAxios.post(
    `external/customer/participation/renew/${campaignUuid}/${customerUuid}`
  );
};

export const trackEvent = (event, options) => {
  const customerUuid = store.getState().userData.customerUuid;
  if (!customerUuid) {
    return;
  }
  if (!srcSource) {
    srcSource = localStorage.getItem("userCameFrom") || "email";
  }

  const jsonData = {
    eventType: "success",
    event,
    srcSource,
    useragent: window.navigator.userAgent,
    url: window.location.href,
    windowWidth: String(window.innerWidth),
    windowHeight: String(window.innerHeight),
    displayWidth: String(window.screen.width),
    displayHeight: String(window.screen.height),
    causerType: "customer",
    causerID: customerUuid,
    customerUuid,
    campaignUuid,
    ...options,
  };

  // console.log(jsonData)
  axios
    .post(`${baseTrackURL}/external/track/customer`, jsonData)
    .catch((err) => console.error(err));
};

async function poll(options) {
  const { fn, validateFn, delay, times } = options;
  let retriesCount = times;
  let result;

  await retry();
  while (!validateFn(result.data) && retriesCount > 0) {
    await wait(delay);
    await retry();
    retriesCount--;

    if (retriesCount === 0) {
      throw new Error("Poolling Error occured");
    }
  }
  return result.data;

  async function retry() {
    try {
      result = await fn();
    } catch (error) {
      result = null;
    }
  }
}

function wait(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
