import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Layout = ({children}) => {
	const location = useLocation()

	useEffect(() => {
		if (!location.pathname.includes('/login') && location.pathname !== '/404') {
		    localStorage.setItem('lastRoute', location.pathname)
		}
	  }, [location])

	return(
		<div className="page">
		    {children}
		</div>
	)
}

export default Layout