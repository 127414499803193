import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux"
import { BrowserRouter } from 'react-router-dom'
import { store } from "./redux/store"
import './styles/index.scss'
import App from './App'
import { AxiosInterceptor } from './helpers/AxiosInterceptor'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AxiosInterceptor>
        <App />
      </AxiosInterceptor>
    </BrowserRouter>
  </Provider>
)
