import jwt from 'jwt-decode'
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate} from "react-router-dom";
import { getLoginPersonalization } from "../../helpers/services";
import { getIsAuth, getPersonalDataLoaded } from "../../redux/userSelectors";
import { actions } from "../../redux/userSlice";
import Preloader from "../Preloader";


export const RequireAuth = () => {
    const isAuth = useSelector(getIsAuth)
    const personalDataLoaded = useSelector(getPersonalDataLoaded)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const setAuth = useCallback((username) => {
        const storedAccessToken = localStorage.getItem('accessToken')
        if (!storedAccessToken) {
         return navigate(`/login/${username}`)
        }
        const tokenExpairDate = jwt(storedAccessToken).exp
        const isLogged = tokenExpairDate * 1000 > Date.now()
        if(isLogged){
          dispatch(actions.setIsAuth(isLogged))
        } else {
          return navigate(`/login/${username}`)
        }

    },[dispatch, navigate])
    
    const loginPersonalization = useCallback(async (name) => {
        try {
            dispatch(actions.setUserName(name))
            const {data} = await getLoginPersonalization(name)
            dispatch(actions.setUserData({customerUuid: data.customer_uuid}))
            dispatch(actions.setContentData(data.personalized_data))
        } catch (error) {
          setIsLoading(false)
          navigate('/404')
                console.warn(error)
          }
    },[dispatch, navigate])
    
    useEffect(() => {
		const storedUsername = localStorage.getItem('username')
    const shouldFetchData = storedUsername && !personalDataLoaded
		
    if (shouldFetchData) {
      setIsLoading(true)
			loginPersonalization(storedUsername)
       .then(() => {
        setAuth(storedUsername)
        setIsLoading(false)
      })
		} 

    if(!storedUsername) return navigate('/login')

	  }, [setAuth, loginPersonalization, navigate, personalDataLoaded])

    return isAuth  ? <Outlet /> : isLoading  ? <Preloader/> : <Outlet />
    
            
    
    
}

