import { useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { baseAxios, mediaAxios } from "../helpers/services";



const AxiosInterceptor = ({ children }) => {

    const navigate = useNavigate();

    const reqInterceptor = config => {
        const token = localStorage.getItem('accessToken')
        if (token && !config.headers['Authorization']) {
          config.headers['Authorization'] = 'Bearer ' + token
        }
        return config
    }

    const reqErrInterceptor = error => Promise.reject(error)

    const resInterceptor = response => response

    const resErrInterceptor = error => {

        if (error.response.status === 401) {
            navigate(`/login/${localStorage.getItem('username')}`);
        }

        return Promise.reject(error);
    }


    useEffect(() => {
        const baseRequestInterceptor = baseAxios.interceptors.request.use(reqInterceptor, reqErrInterceptor);
        const mediaRequestInterceptor = mediaAxios.interceptors.request.use(reqInterceptor, reqErrInterceptor);

        const baseResponseInterceptor = baseAxios.interceptors.response.use(resInterceptor, resErrInterceptor);
        const mediaResponseInterceptor = mediaAxios.interceptors.response.use(resInterceptor, resErrInterceptor);

        return () => {
            baseAxios.interceptors.request.eject(baseRequestInterceptor);
            mediaAxios.interceptors.request.eject(mediaRequestInterceptor);
            
            baseAxios.interceptors.response.eject(baseResponseInterceptor);
            mediaAxios.interceptors.response.eject(mediaResponseInterceptor);
        }

    }, [])

    return children;
}

export { AxiosInterceptor }