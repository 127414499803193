import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createCustomer, retrieveAccessKeycloakToken, setUser } from "../helpers/services"

export const initialState = {
  username: null,
  isAuth: false,
  personalDataLoaded: false,
  isLoading: false,
  isError: false,
  userData: {},
  contentData: {},
}

const setPersonalDataLoaded = (state, { payload }) => {
  state.personalDataLoaded = payload
}
export const createNewCustomer = createAsyncThunk("user/createNewCustomer", async ({ email, tel }, { getState }) => {
  const keyClockToken = await retrieveAccessKeycloakToken()
  const { userData } = getState()
  const newCustomer = {
    campaign_uuid: process.env.REACT_APP_NEW_CUSTOMER_CAMPAIGN_UUID,
    ...userData,
    s_email_Kunde: email,
    s_mobile_Kunde: tel,
    has_appointment: 1,
    userIdentifier: userData.sdk_user_id,
  }
  await createCustomer(newCustomer, keyClockToken)
  return newCustomer
})
export const sendContactForm = createAsyncThunk("user/sendContactForm", async ({ email, tel }) => {
  const payload = {
    s_email_Kunde: email,
    s_mobile_Kunde: tel,
    has_appointment: 1,
  }
  await setUser(payload)
  return payload
})

const setUserName = (state, { payload }) => {
  state.username = payload
}

const setIsAuth = (state, { payload }) => {
  state.isAuth = payload
}

const setUserData = (state, { payload }) => {
  state.userData = {
    ...state.userData,
    ...payload,
  }
}

const setContentData = (state, { payload }) => {
  state.contentData = {
    ...state.contentData,
    ...payload,
  }
}

const userDataSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserName,
    setIsAuth,
    setUserData,
    setContentData,
    setPersonalDataLoaded,
  },
  extraReducers: {
    [sendContactForm.pending]: (state) => {
      state.isLoading = true
    },
    [sendContactForm.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.sendContactForm = true
      state.userData = {
        ...state.userData,
        ...payload,
      }
    },
    [sendContactForm.rejected]: (state) => {
      state.isLoading = false
      state.isError = true
    },
    [createNewCustomer.rejected]: (state) => {
      state.isLoading = false
      state.isError = true
    },
  },
})

export default userDataSlice.reducer
export const actions = { ...userDataSlice.actions }
